<template>
  <div>
    <div class="flex justify-end">
      <div class="w-full sm:w-1/2 mb-5">
        <input
          type="search"
          name="search"
          class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-14 w-full"
          placeholder="Search for employee, etc."
          v-model="searchQuery"
          @keyup.enter="$refs.table.loadAjaxData"
        />
        <ion-icon
          name="search-outline"
          class="text-blue-200 absolute z-10 text-lg ml-3 left-0"
          style="top: 50%; transform: translateY(-50%);"
        />
      </div>
    </div>
    <div
      class="border border-solid border-blue-200 rounded overflow-hidden mb-10"
    >
      <datatable
        :index="true"
        :reverse-index="true"
        :url="`${this.$baseurl}/admin/personal/cards/repayments`"
        :ajax="true"
        :exportable="true"
        :fillable="true"
        :ajaxPagination="true"
        :data="cards"
        :columns="columns"
        :loading="loading"
        :query="searchQuery"
        :rangeable="dateRange"
        ref="table"
      />
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  /*props: {
		searchQuery: {
			type: String,
			default: "",
		},
	},*/
  data() {
    return {
      searchQuery: "",
      company: null,
      loading: false,
      cards: [],
      columns: [
        {
          name: "user",
          th: "User",
          render: (card) => {
            const user = card?.user;
            if (!user) {
              return "N/A";
            }
            return `${user?.name} ${user?.last_name || ""}`;
          },
        },
        {
          name: "company",
          th: "Company",
          render: (card) => {
            const company = card?.user?.company;
            if (!company) {
              return "---";
            }
            return company.name;
          },
        },
        {
          name: "amount",
          th: "Amount",
          render: (card) => {
            const amount = card?.amount;
            return `₦ ${this.$options.filters.currency(amount)}`;
          },
        },
        {
          name: "due_date",
          th: "Payment Date",
          render: (card) => {
            const profile = card?.created_at;
            if (!profile) {
              return "N/A";
            }
            // const salary_day = profile.salary_day;
            // const billing_date = profile.billing_date;
            // const date = new Date();
            // const payment_date = salary_day + billing_date;
            // date.setDate(payment_date);
            // return date.format('D, M dd Y');
            return moment(profile).format("MMM Do YYYY");
          },
        },
        {
          name: "status",
          th: "Status",
          render: (card) => {
            switch (card?.status) {
              case "approve":
                return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
									Approved
								</div>`;
              case "decline":
                return `<div class="rounded-sm border border-red-500 text-red-500 bg-red-100 text-xs px-3 py-2 inline-block">
									Declined
								</div>`;
              case "failed":
                return `<div class="rounded-sm border border-red-500 text-red-500 bg-red-100 text-xs px-3 py-2 inline-block">
									Failed
								</div>`;  
              case "pending":
              default:
                return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
									${card?.status}
								</div>`;
            }
          },
        },
      ],
      dateRange: {
        value: {
          startDate: null,
          endDate: null,
        },
        format: "dd/mm/yyyy",
      },
    };
  },
  /*beforeMount() {
		this.getRepayments();
	},*/
  mounted() {},
  methods: {
    async getRepayments() {
      this.loading = true;
      await this.sendRequest("admin.cards.personal.repayments", {
        success: (response) => {
          this.cards = response.data.cards;
        },
      });
      this.loading = false;
    },
  },
};
</script>
